<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <el-form-item label="类型:" label-width="47px" class="w-170">
          <el-select v-model="table.where.type" clearable>
            <el-option v-for="item in orderType" :key="item.type" :value="item.type" :label="item.type_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" label-width="50px" class="w-200">
          <el-input v-model="table.where.name" placeholder="请输入用户名" clearable/>
        </el-form-item>
        <el-form-item label="手机号:" label-width="62px" class="w-200">
          <el-input v-model="table.where.phone" placeholder="请输入手机号" clearable/>
        </el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-150">
          <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">搜索</el-button>
          <el-button @click="(table.where={m_type:10})&&$refs.table.reload()">重置</el-button>
<!--          <download  style="margin-left: 0" class="ele-btn-icon ml-20"  label="导出" url="driver/exportExcelForBlackNew"-->
<!--                     :params="exportOrderParams()"/>-->
<!--          <el-button icon="el-icon-download" type="success" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
        <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>                        
          <el-table-column prop="type" label="类型"  show-overflow-tooltip min-width="80">
						<template slot-scope="scope">
							<!-- {{scope.row.type==1?'跑腿':scope.row.type==2?'超市':scope.row.type==3?'叫餐':''}} -->
							{{scope.row.type==1?'超市':(scope.row.type==2?'叫餐':'类型未定义')}}
						</template>
					</el-table-column>
          <el-table-column prop="true_name" label="商家姓名"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="160"/>
          <el-table-column prop="name" label="店铺名称"  show-overflow-tooltip min-width="160"/>
          <el-table-column prop="driver_area" label="所属地区"  show-overflow-tooltip min-width="100">
          <template slot-scope="scope">
							{{scope.row.province_name}}{{scope.row.city_name}}{{scope.row.area_name}}
						</template>
					</el-table-column>
          <el-table-column prop="credit" label="评分"  show-overflow-tooltip min-width="100"/>
          <el-table-column label="注册时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="上次登录时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:merchant_mblacklist:view')">查看</el-link>
              <el-link @click="handlerecovery(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:merchant_mblacklist:huifu')">恢复</el-link>
              <el-link @click="remove(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:merchant_mblacklist:delete')" command="remove">删除</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看商家详情 -->
    <el-dialog v-dialogDrag title="查看商家详情" :visible.sync="dialogTableVisibleCheck" width="850px" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm"   label-width="200px">
						<div style="display: flex;">
							<div style="padding-right: 50px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="今日取消订单量:" prop="money">
								{{checkForm.today_cancel}}
							</el-form-item>
							<el-form-item label="本月累计取消订单量:" prop="invite_money">
								{{checkForm.thisMon_cancel}}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{checkForm.month_order_total}}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{checkForm.month_order_price}}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{checkForm.month_order_earning}}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{checkForm.month_order_fee}}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{checkForm.month_order_promote}}
							</el-form-item>
							<el-form-item label="商家姓名：" prop="true_name" >
								{{checkForm.true_name}}
							</el-form-item>
							<el-form-item label="头像：">
								<el-image v-if="checkForm.headimg" style="width:70px;height:70px;" :src="checkForm.headimg"
									fit="cover" :preview-src-list="[checkForm.headimg]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="是否载客" prop="phone">
								{{checkForm.is_carry}}
							</el-form-item> -->
							<el-form-item label="手机号：">
								{{checkForm.phone}}
							</el-form-item>
							<el-form-item label="所属区域：">
								{{checkForm.driver_area}}
							</el-form-item>
							<el-form-item label="累计接单收益：">
								{{checkForm.money}}
							</el-form-item>
							<el-form-item label="诚信度：">
								{{checkForm.credit}}
							</el-form-item>
							<el-form-item label="累计在线时长：">
								{{checkForm.total_time}} 分钟
							</el-form-item>
							<el-form-item label="最近的登录时间：">
								{{checkForm.active_time*1000 | toDateString }}
							</el-form-item>
						</div>
						<div>
							<el-form-item label="注册时间：">
								{{checkForm.create_time*1000 | toDateString }}
							</el-form-item>
							<el-form-item label="真实姓名：">
								{{checkForm.true_name}}
							</el-form-item>
							<el-form-item label="身份证号：">
								{{checkForm.idcard}}
							</el-form-item>
							<el-form-item label="支付宝账号：" prop="idcard">
								{{checkForm.ali_number?checkForm.ali_number:'无'}}
							</el-form-item>
							<!-- <el-form-item label="车牌号：" prop="car_number" v-if="checkForm.type == 4">
								{{checkForm.car_number}}
							</el-form-item> -->
							<el-form-item label="手持身份证照片：">
								<el-image v-if="checkForm.idcard_hand" style="width:70px;height:70px;" :src="checkForm.idcard_hand"
									fit="cover" :preview-src-list="[checkForm.idcard_hand]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证正面照：">
								<el-image v-if="checkForm.idcard_front" style="width:70px;height:70px;" :src="checkForm.idcard_front"
									fit="cover" :preview-src-list="[checkForm.idcard_front]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证反面照：">
								<el-image v-if="checkForm.idcard_back" style="width:70px;height:70px;" :src="checkForm.idcard_back"
									fit="cover" :preview-src-list="[checkForm.idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="驾驶证正面照：" v-if="checkForm.type == 4">
								<el-image style="width:70px;height:70px;" :src="checkForm.jiacard_front"
									fit="cover" :preview-src-list="[checkForm.jiacard_front]"></el-image>
							</el-form-item> -->
							<el-form-item label="营业执照：" v-if="checkForm.type == 3 || checkForm.type == 5">
								<el-image v-if="checkForm.business_license" style="width:70px;height:70px;" :src="checkForm.business_license"
									fit="cover" :preview-src-list="[checkForm.business_license]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="食品安全许可证：" v-if="checkForm.type == 5">
								<el-image v-if="checkForm.food_safety" style="width:70px;height:70px;" :src="checkForm.food_safety"
									fit="cover" :preview-src-list="[checkForm.food_safety]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证正面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_font" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_font"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_font]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证反面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_back" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_back"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
						</div>
					</div>
      </el-form>
      </el-card>
    </el-dialog>
    <!-- 编辑商家 -->
    <el-dialog v-dialogDrag title="编辑商家" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="170px">
        <el-form-item label="真实姓名：" prop="true_name" >
          <el-input v-model="editForm.name" placeholder="请输入" clearable/>
        </el-form-item>
        <el-form-item label="联系方式：" prop="phone">
          <el-input v-model="editForm.phone" placeholder="请输入" clearable/>
        </el-form-item>
        <el-form-item label="所属区域：">
          <el-select v-model="editForm.pid"  @change="handleChangeProv1(editForm.pid)" class="selectStyle" placeholder="请选择省">
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
          <el-select v-model="editForm.cid"  @change="handleChangeCity1(editForm.cid)" class="selectStyle ml-10" placeholder="请选择市">
            <el-option v-for="option in cityArr" :value="option.name" :key="option.cid" :label="option.name"></el-option>
          </el-select>
          <el-select v-model="editForm.aid"   @change="$forceUpdate()" class="selectStyle ml-10" placeholder="请选择区/县">
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型:" >
          <el-select v-model="editForm.type" placeholder="请选择类型">
            <el-option label="超市" value="3"></el-option>
            <el-option label="跑腿" value="4"></el-option>
            <el-option label="叫餐" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="累计接单收益：" prop="money">
          {{editForm.money}}
        </el-form-item>
        <el-form-item label="推广总收益：" prop="invite_money">
          {{editForm.invite_money}}
        </el-form-item>
        <el-form-item label="累计在线时长：" prop="total_time">
          {{editForm.total_time}} 分钟
        </el-form-item>
        <el-form-item label="诚信度：" prop="invite_money">
          {{editForm.credit}}
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-radio-group v-model="editForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idcard">
          {{editForm.idcard}}
        </el-form-item>


        <el-form-item label="真实头像：" >
          <uploadImage :limit="1" v-model="editForm.true_head"></uploadImage>
        </el-form-item>
        <el-form-item label="手持身份证照片：" >
          <uploadImage :limit="1" v-model="editForm.idcard_hand"></uploadImage>
        </el-form-item>
        <el-form-item label="身份证正面照：" >
          <uploadImage :limit="1" v-model="editForm.idcard_front"></uploadImage>
        </el-form-item>
        <el-form-item label="身份证反面照：" >
          <uploadImage :limit="1" v-model="editForm.idcard_back"></uploadImage>
        </el-form-item>


        <el-form-item label="营业执照：" >
          <uploadImage :limit="1" v-model="editForm.taxi_operation"></uploadImage>
        </el-form-item>



        <el-form-item label="累计接单量：" prop="orders">
          {{editForm.orders}}
        </el-form-item>
        <el-form-item label="注册时间：" prop="create_time">
          {{ editForm.create_time*1000 | toDateString }}
        </el-form-item>
        <el-form-item label="最近活跃时间：" prop="active_time">
          {{ editForm.active_time*1000 | toDateString }}
        </el-form-item>
        <el-form-item label="用户余额：" prop="money">
          <el-input v-model="editForm.money" placeholder="" clearable/>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="1">未完善信息</el-radio>
            <el-radio :label="2">正常</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="dialogTableVisibleEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uploadImage from '@/components/uploadImage'

export default {
  name: "SysUser",
  data() {
    return {
      // m_type:10
      table: {url: '/store/listBlacklist', where: {}},  // 表格配置
      table1: {url: '/driver/driver_check_log', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
        // phone: [
        //   {required: true, message: '请输入手机号', trigger: 'blur'}
        // ],
        // name: [
        //   {required: true, message: '请输入昵称', trigger: 'blur'}
        // ],
      },
      provArr:[],
      cityArr:[],
      districtArr:[],
      checkForm:{},
      recordsList:[],
      startTime: '',
      endTime: '',
      driverData:{},
      dialogTableVisibleEdit:false,
      dialogTableVisibleCheck:false,
      dialogTableVisibleRecords:false,
      dialogTableVisibleCarRecords:false,
      pageSize:10,
      pageNumber:1,
      recordsTotal:1000,
      recordsShow:false,
      recordsId:'',
      loading:true,
      pid:'',
      cid:'',
      aid:'',
			orderType: [{
				type: 4,
				type_name: '跑腿'
			}, {
				type: 3,
				type_name: '超市'
			}, {
				type: 5,
				type_name: '叫餐'
			}],
    }
  },
  created(){


    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },
  components: {uploadImage},
  mounted() {
  },
  methods: {
    dropClick(command,row){
      if(command==='edit'){// 查看用户信息
        this.edit()
      }else if(command==='remove'){//删除
        this.remove(row)
      }else if(command==='edit'){//修改用户信息
        this.edit(row)
      }
    },

    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
     handleChangeProv1(e){
      /** 获取被选省省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.editForm.cid=''
        this.editForm.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity1(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.editForm.aid=''
      })
			}else{
				this.editForm.pid = ''
				this.editForm.aid = ''
			}
    },

    /**
     *恢复商家信息
     **/
    handlerecovery(row){
      
      this.$confirm('确定恢复该商家吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/store/editRecoverBlack', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
     

    },
    /**
     *查看用户信息
     **/
    handlecheck(row){
      this.dialogTableVisibleCheck=true
      //console.log(row)
      if(row.sex==0){
        row.sex='男'
      }else{
        row.sex='女'
      }
      if(row.is_carry==1){
        row.is_carry='载客'
      }else{
        row.is_carry='空闲'
      }
      if(row.status==2){
        row.status='正常'
      }else(
          row.status='待完善信息'
      )
      this.checkForm = row;
    },

    getRecords(id){
      var formData={
        id:id,
        pageSize:this.pageSize,
        pageNumber:this.pageNumber,
        strat_time:this.startTime,
        end_time:this.endTime

      }
      this.$http.post('/driver/driver_check_log',formData).then(res=>{
        var data=JSON.parse(res.data)
        this.recordsTotal=data.total
        if(data.total==0 || data.total=='null'){
          this.recordsShow=false
        }else{
          this.recordsShow=true
        }
        if(data.rows.length){
          this.recordsList=data.rows

        }


      })
    },
    /**
     *查询
     **/
    searchRecords(){


      this.getRecords(this.recordsId)
    },
    /**
     *分页
     **/
    handleCurrentChange(e){
      //console.log(e)
    },
    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/driver/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          this.showEdit = true;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/driver/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
    this.$confirm('确定删除该商家吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/store/deleteStore', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
     
    },

   
    /* 导出数据Excel */
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      // let name = this.table.where.name;
      // let phone = this.table.where.phone;
      // let cid = this.table.where.cid;
      // let pid = this.table.where.pid;
      // let area_id = this.table.where.area_id;
      // let page = this.table.where.area_id;
      //console.log('user data export')
      this.$http
          // .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
          .get("/driver/exportExcel", info)
          .then((res) => {
            let data = res.data;
            if (data.code == 0) {
              // 下载文件
              window.location.href = data.data;
              this.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              this.$message.error("导出失败");
            }
          })
    },
  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}
/deep/.el-dialog{
	margin-top: 60px !important;
}


</style>